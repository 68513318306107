:root {
    --color-base-light: #fafafa;
    --color-gray-200: #dce3ef;
    --color-gray-600: #455065;
    --color-dark-blue-25: #99a0e4;
    --color-dark-blue-100: #4049a8;
    --color-dark-blue-300: #2151fe;
    --color-dark-blue-400: #1f267b;
    --color-dark-blue-600: #1928ef;
    --color-dark-blue-800: #090d38;
    --color-base-blue-ui: #111648;
    --default-transition: 0.3s ease;
}

html {
    background: radial-gradient(
            250% 250% at 50% -9.17%,
            rgba(45, 55, 163, 0.7) 0%,
            rgba(9, 13, 56, 0.7) 45.09%,
            rgba(5, 8, 36, 0.7) 100%
        ),
        linear-gradient(0deg, #030412 0%, #030412 100%), #070a2d;
    height: 100vh;
    background-repeat: no-repeat;
}

body {
    margin: 0;
    font-family:
        'Poppins',
        'Karla',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent !important;
}

summary::-webkit-details-marker,
summary::marker {
    display: none;
}
